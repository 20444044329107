import { __decorate } from "tslib";
import { Component, useModule, Vue, Watch } from "@/types";
import "./actionButtons.scss";
import { Loader } from "@/ui-kit";
import { DropDown, Icon, Input } from "@/ui-kit";
import { debounce } from "@/helpers/decorators/debounce";
import { concatFilters } from "@/helpers";
let ActionButtons = class ActionButtons extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.miniSearch = false;
        this.filterSelect = [
            { id: 1, name: "ФИО", oData: "name" },
            { id: 2, name: "Табельный номер", oData: "staffNumber" },
            { id: 3, name: "Подразделение", oData: "departmentName" },
            { id: 4, name: "Номер ключа", oData: "rfid" },
            { id: 5, name: "График работы", oData: "workScheduleName" },
            { id: 6, name: "График доступа", oData: "accessScheduleName" },
        ];
        this.selected = this.filterSelect[0].id;
        this.selectedName = this.filterSelect[0].oData;
        this.searching = "";
        this.playPause = "play";
        this.isPlayPauseExist = false;
    }
    mounted() {
        if (this.route === "GuestPasses") {
            const odataDropdown = [1, 4, 6];
            this.filterSelect = this.filterSelect.filter((item) => odataDropdown.includes(item.id));
        }
    }
    async monitoringActionHandler() {
        if (this.route === "Syslog") {
            this.isPlayPauseExist = true;
        }
        if (this.route === "Passlog") {
            try {
                await this.store.configurations.get({
                    skip: 0,
                });
                this.isPlayPauseExist = this.store.configurations.list.length >= 1;
            }
            catch (e) {
                console.log(e, "isPlayPauseExist");
            }
        }
    }
    passLogSetType(val) {
        if (this.route === "Passlog" || this.route === "Syslog") {
            this.store.dialog.setPlayPause(val);
        }
    }
    playPauseHandler() {
        this.playPause = this.playPause === "play" ? "pause" : "play";
    }
    setType(name, modalType = "form", details) {
        this.store.dialog.setDialog({
            data: name === "add" ? null : this.store.tableRow.current,
            route: this.$route.name?.toLowerCase(),
            type: modalType,
            name: name,
            details: details,
        });
    }
    setFilterSelect({ id, name }) {
        this.selected = id;
        this.selectedName = this.filterSelect.filter((item) => item.id === id)[0].oData;
        this.searching = "";
    }
    setSearchHandler() {
        if (this.searchType === "full") {
            this.setType("search");
        }
        if (this.searchType === "mini") {
            this.miniSearch = !this.miniSearch;
        }
    }
    get searchType() {
        if (this.route === "Readers") {
            return "full";
        }
        if (this.parentRoute === "FilingCabinet" && this.route !== "Subdivisions") {
            return "mini";
        }
        return "";
    }
    get route() {
        return this.$route.name;
    }
    get specificEdit() {
        //Для страницы графики работ//
        return this.store.tableRow.current?.workScheduleType === 2
            ? "seven"
            : "thirty";
    }
    get parentRoute() {
        return this.$route.matched[0].name;
    }
    get withoutAdded() {
        const pages = ["Dismissed", "WorkSchedules", "Passlog", "Syslog"];
        return !pages.includes(this.route);
    }
    get isExistedPdfBtn() {
        const routes = ["Passlog", "Syslog", "Reports"];
        return routes.includes(this.route);
    }
    get actionButtonsAvailable() {
        return (Object.keys(this.store.orgList.currentOrg).length ||
            this.parentRoute === "Administration");
    }
    /***
     * TODO если потребуется доработка для всех страниц, нужно вынести
     * @param newVal
     * @param oldVal
     */
    search(newVal) {
        let filter = `contains(${this.selectedName}, '${this.searching}')`;
        if (this.selectedName === "name" && this.searching !== "") {
            filter = concatFilters(this.searching);
        }
        if (newVal === "") {
            filter = "";
        }
        this.store.supFilter.setSupportFilter(encodeURIComponent(filter));
    }
    /***
     * Удаляем фильтр из стора
     */
    beforeDestroy() {
        this.store.supFilter.setSupportFilter("");
    }
};
__decorate([
    Watch("isPlayPauseExist", { immediate: true })
], ActionButtons.prototype, "monitoringActionHandler", null);
__decorate([
    Watch("playPause", { immediate: true })
], ActionButtons.prototype, "passLogSetType", null);
__decorate([
    Watch("searching"),
    debounce
], ActionButtons.prototype, "search", null);
ActionButtons = __decorate([
    Component({
        components: { Input, DropDown, Icon, Loader },
    })
], ActionButtons);
export default ActionButtons;
