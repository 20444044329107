import { UserManager } from "oidc-client";
// import { clearEndSlashUrl } from "@/helpers/url";
import { Storage } from "@/helpers/storage";
class AuthService {
    constructor() {
        this.token = null;
        this.inactive = 1800;
        this.testServerHostName = "192.168.28.126";
        this.testServer = {
            HTTPSUri: `https://${this.testServerHostName}`,
            WSSUri: `wss://${this.testServerHostName}`,
        };
        this.location = window.location;
        this.webGatewayUri = "";
        this.webGatewayUriForSocket = "";
        // get getMainServiceUri(): string {
        //   return this.mainServiceUri;
        // }
        // get getStatusUri(): string {
        //   return this.statusUri;
        // }
        // get getIdentityServiceUri(): string {
        //   return this.identityServiceUri;
        // }
        // get getLoggingServiceUri(): string {
        //   return this.loggingServiceUri;
        // }
        // get getLoggingServiceWsUri(): string {
        //   return this.loggingServiceWsUri;
        // }
    }
    // private mainServiceUri = "";
    // private statusUri = "";
    // private identityServiceUri = "";
    // private loggingServiceUri = "";
    // private passEventsUri = "";
    // private loggingServiceWsUri = "";
    async initialize() {
        try {
            this.webGatewayUri = AuthService.IS_DEV
                ? this.testServer.HTTPSUri
                : this.location.origin;
            this.webGatewayUriForSocket = AuthService.IS_DEV
                ? this.testServer.WSSUri
                : this.location.protocol === "http:"
                    ? `ws://${this.location.hostname}`
                    : `wss://${this.location.hostname}`;
            const IS_DEV = AuthService.IS_DEV
                ? "http://localhost:8080"
                : this.webGatewayUri;
            const settings = {
                /* URI службы идентификации. Данный URI нужно получать от основной службы системы либо через CGI-запрос, не требующий аутентификации,
                   либо в рамках загрузки index.html. */
                authority: this.webGatewayUri,
                /* Идентификатор клиента. Не меняется. */
                client_id: "webclient",
                /* URI, на который будет происходить обратный редирект после прохождения пользователем аутентификации на веб-сервере службы идентификации.
                   Должен формироваться динамически в зависимости от того, на каком адресе работает основной веб-интерфейс системы.
                   Часть post-login-callback.html является постоянной. Менять ее не нужно. */
                redirect_uri: `${IS_DEV}/post-login-callback.html`,
                /* URI, на который будет происходить обратный редирект после разлогинивания пользователя на веб-сервере службы идентификации.
                   Должен подставляться динамически в зависимости от того, на каком адресе работает основной веб-интерфейс системы. */
                post_logout_redirect_uri: this.webGatewayUri,
                /* Тип потока OpenID Connect: Authorization Code Flow with Proof Key for Code Exchange (PKCE). Не меняется. */
                response_type: "code",
                /* Виды токенов и другая информация, которую мы запрашиваем у службы идентификации:
                   openid - запрос access token и ID token, это обязательный параметр;
                   profile - запрос дополнительных свойств пользователя, помимо его ID (например, имени);
                   offline_access - запрос refresh token. */
                scope: "openid profile api permissions offline_access",
                /* Флаг, указывающий, нужно ли делать автоматическое обновление токена доступа за 1 минуту до окончания его времени жизни. */
                automaticSilentRenew: true,
            };
            this.userManager = new UserManager(settings);
            this.userManager.events.addUserLoaded((user) => {
                const { access_token } = user;
                if (access_token) {
                    this.token = access_token;
                }
            });
            // this.mainServiceUri = AuthService.IS_DEV
            //   ? this.testServer.HTTPSUri
            //   : IS_HTTP_URI;
            // this.statusUri = AuthService.IS_DEV
            //   ? this.testServer.WSSUri
            //   : IS_URI_FOR_SOCKET;
            // this.identityServiceUri = AuthService.IS_DEV
            //   ? this.testServer.HTTPSUri
            //   : IS_HTTP_URI;
            // this.loggingServiceUri = AuthService.IS_DEV
            //   ? this.testServer.HTTPSUri
            //   : IS_HTTP_URI;
            // this.passEventsUri = AuthService.IS_DEV
            //   ? this.testServer.WSSUri
            //   : IS_URI_FOR_SOCKET;
            // this.loggingServiceWsUri = AuthService.IS_DEV
            //   ? this.testServer.WSSUri
            //   : IS_URI_FOR_SOCKET;
            await this.currentUser();
        }
        catch (e) {
            console.log(e);
        }
    }
    login() {
        return this.userManager.signinRedirect();
    }
    logout() {
        Storage.remove("organization");
        return this.userManager.signoutRedirect();
    }
    getUser() {
        return this.userManager.getUser();
    }
    async currentUser() {
        try {
            const { profile: { name, sections, sub, inactivity_timeout }, access_token, refresh_token, } = await this.getUser();
            if (access_token) {
                this.token = access_token;
                this.inactive = parseInt(inactivity_timeout);
            }
            return {
                name,
                sections,
                access_token,
                refresh_token,
            };
        }
        catch (e) {
            // console.log(e);
        }
    }
    async isLoggedIn() {
        try {
            const user = await this.getUser();
            return user !== null && !user.expired;
        }
        catch (e) {
            return false;
        }
    }
    get getToken() {
        return this.token;
    }
    get getInactive() {
        return this.inactive;
    }
    get getWebGatewayUri() {
        return this.webGatewayUri;
    }
    get getWebGatewayUriForSocket() {
        return this.webGatewayUriForSocket;
    }
}
AuthService.IS_DEV = process.env.NODE_ENV === "development";
const Auth = new AuthService();
export { Auth };
