import { __decorate } from "tslib";
import { Module } from "@/types";
import { TableRowModule } from "@/store/modules/currentTableRow";
import { Dialog } from "@/store/modules/dialog";
import { Menu } from "@/store/modules/menu";
import { NotifyModule } from "@/store/modules/notification";
import { ValidationField } from "@/store/modules/validationField";
import { OrganizationDropdownModule } from "@/store/modules/organizationDropdown";
import { SupportingFilterModule } from "@/store/modules/supportingFilter";
/**Administration**/
import { ReadersModule } from "./modules/administration/readers";
import { ZonesModule } from "@/store/modules/administration/zones";
import { AccessPointsModule } from "@/store/modules/administration/accessPoints";
import { UsersModule } from "@/store/modules/administration/users";
import { UsersLoginModule } from "@/store/modules/administration/usersLogin";
import { AccessSchedulesModule } from "@/store/modules/administration/accessShedules";
import { OrganizationModule } from "@/store/modules/administration/organization";
/**FillingCabinet**/
import { SubdivisionModule } from "@/store/modules/filing-cabinet/subdivision";
import { EmployeesModule } from "@/store/modules/filing-cabinet/employees";
import { PersonsModule } from "@/store/modules/filing-cabinet/persons";
import { GuestPassesModule } from "@/store/modules/filing-cabinet/guestPasses";
/**Monitoring**/
import { ConfigurationsModule } from "@/store/modules/monitoring/сonfigurations";
import { PassLogModule } from "@/store/modules/monitoring/passLog";
import { PassMethodsModule } from "@/store/modules/monitoring/passMethods";
import { SysLogModule } from "@/store/modules/monitoring/sysLog";
import { SystemEventsFilterModule } from "@/store/modules/monitoring/systemEventsFilter";
import { SystemEventsModule } from "@/store/modules/monitoring/systemEvents";
import { SystemEventsLevelsModule } from "@/store/modules/monitoring/systemEventsLevels";
import { SystemEventsSourcesModule } from "@/store/modules/monitoring/systemEventsSources";
/**TimeAttendance**/
import { DayIntervalsModule } from "@/store/modules/time-attendance/dayIntervals";
import { PassEventsFilterModule } from "@/store/modules/monitoring/passEventsFilter";
import { WorkSchedulesModule } from "@/store/modules/time-attendance/workSchedules";
import { HolidaysModule } from "@/store/modules/time-attendance/holidays";
import { AdjustmentsModule } from "@/store/modules/time-attendance/adjustments";
import { ReportsModule } from "@/store/modules/time-attendance/reports";
import { Absences } from "@/store/modules/time-attendance/absences";
import { StatusReport } from "@/store/modules/time-attendance/statusReport";
export default class Pages {
    constructor() {
        /**Administration**/
        this.readers = new ReadersModule();
        this.zones = new ZonesModule();
        this.accessPoints = new AccessPointsModule();
        this.users = new UsersModule();
        this.usersLogin = new UsersLoginModule();
        this.accessSchedules = new AccessSchedulesModule();
        this.organization = new OrganizationModule();
        /**FillingCabinet**/
        this.subdivisions = new SubdivisionModule();
        this.employees = new EmployeesModule();
        this.persons = new PersonsModule();
        this.guestPasses = new GuestPassesModule();
        /**Monitoring**/
        this.configurations = new ConfigurationsModule();
        this.passLog = new PassLogModule();
        this.sysLog = new SysLogModule();
        this.systemEvents = new SystemEventsModule();
        this.systemEventsLevels = new SystemEventsLevelsModule();
        this.systemEventsSources = new SystemEventsSourcesModule();
        this.passMethods = new PassMethodsModule();
        /**TimeAttendance**/
        this.dayIntervals = new DayIntervalsModule();
        this.workSchedules = new WorkSchedulesModule();
        this.holidays = new HolidaysModule();
        this.adjustments = new AdjustmentsModule();
        this.reports = new ReportsModule();
        this.absences = new Absences();
    }
}
__decorate([
    Module()
], Pages.prototype, "readers", void 0);
__decorate([
    Module()
], Pages.prototype, "zones", void 0);
__decorate([
    Module()
], Pages.prototype, "accessPoints", void 0);
__decorate([
    Module()
], Pages.prototype, "users", void 0);
__decorate([
    Module()
], Pages.prototype, "usersLogin", void 0);
__decorate([
    Module()
], Pages.prototype, "accessSchedules", void 0);
__decorate([
    Module()
], Pages.prototype, "organization", void 0);
__decorate([
    Module()
], Pages.prototype, "subdivisions", void 0);
__decorate([
    Module()
], Pages.prototype, "employees", void 0);
__decorate([
    Module()
], Pages.prototype, "persons", void 0);
__decorate([
    Module()
], Pages.prototype, "guestPasses", void 0);
__decorate([
    Module()
], Pages.prototype, "configurations", void 0);
__decorate([
    Module()
], Pages.prototype, "passLog", void 0);
__decorate([
    Module()
], Pages.prototype, "sysLog", void 0);
__decorate([
    Module()
], Pages.prototype, "systemEvents", void 0);
__decorate([
    Module()
], Pages.prototype, "systemEventsLevels", void 0);
__decorate([
    Module()
], Pages.prototype, "systemEventsSources", void 0);
__decorate([
    Module()
], Pages.prototype, "passMethods", void 0);
__decorate([
    Module()
], Pages.prototype, "dayIntervals", void 0);
__decorate([
    Module()
], Pages.prototype, "workSchedules", void 0);
__decorate([
    Module()
], Pages.prototype, "holidays", void 0);
__decorate([
    Module()
], Pages.prototype, "adjustments", void 0);
__decorate([
    Module()
], Pages.prototype, "reports", void 0);
__decorate([
    Module()
], Pages.prototype, "absences", void 0);
export class AppStore extends Pages {
    constructor() {
        super(...arguments);
        this.statusReport = new StatusReport();
        this.menuModule = new Menu();
        this.tableRow = new TableRowModule();
        this.dialog = new Dialog();
        this.notification = new NotifyModule();
        this.validationField = new ValidationField();
        this.orgList = new OrganizationDropdownModule();
        this.statusIndication = null;
        this.supFilter = new SupportingFilterModule();
        /**
         * Возвращает обьект данных без total
         */
        this.passEventsFilter = new PassEventsFilterModule();
        this.systemEventsFilter = new SystemEventsFilterModule();
    }
}
__decorate([
    Module()
], AppStore.prototype, "statusReport", void 0);
__decorate([
    Module()
], AppStore.prototype, "menuModule", void 0);
__decorate([
    Module()
], AppStore.prototype, "tableRow", void 0);
__decorate([
    Module()
], AppStore.prototype, "dialog", void 0);
__decorate([
    Module()
], AppStore.prototype, "notification", void 0);
__decorate([
    Module()
], AppStore.prototype, "validationField", void 0);
__decorate([
    Module()
], AppStore.prototype, "orgList", void 0);
__decorate([
    Module()
], AppStore.prototype, "statusIndication", void 0);
__decorate([
    Module()
], AppStore.prototype, "supFilter", void 0);
__decorate([
    Module()
], AppStore.prototype, "passEventsFilter", void 0);
__decorate([
    Module()
], AppStore.prototype, "systemEventsFilter", void 0);
