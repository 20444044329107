export function createService(service, axios) {
    return new service(axios);
}
export function createGetUrlWithOptions(baseUrl, options) {
    ////www.odata.org/documentation/odata-version-2-0/uri-conventions/
    if (options) {
        let filterCreate = "";
        Object.entries(options).forEach(([key, val]) => {
            if (key === "top" && val === 0)
                return;
            if (val !== "") {
                filterCreate += `&${key}=${val}`;
            }
        });
        return `${baseUrl}?${filterCreate}&total=true`;
    }
    return `${baseUrl}?top=${0}&skip=${0}&total=true`;
}
