import { __decorate } from "tslib";
import { Action, Getter, Mutation, State } from "@/types";
import { service } from "@/services";
export class PersonsModule {
    constructor() {
        this.persons = [];
        this.count = 0;
        this.axiosError = null;
    }
    setPersons(persons) {
        this.persons = persons;
    }
    setTotal(count) {
        this.count = count;
    }
    setError(error) {
        this.axiosError = error;
    }
    get list() {
        return this.persons;
    }
    get total() {
        return this.count;
    }
    get errorList() {
        return this.axiosError;
    }
    async get(options) {
        try {
            const data = await service.persons.get(options);
            this.setPersons(data.items);
            this.setTotal(data.total);
        }
        catch (error) {
            this.setError(error);
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
}
__decorate([
    State()
], PersonsModule.prototype, "persons", void 0);
__decorate([
    State()
], PersonsModule.prototype, "count", void 0);
__decorate([
    State()
], PersonsModule.prototype, "axiosError", void 0);
__decorate([
    Mutation()
], PersonsModule.prototype, "setPersons", null);
__decorate([
    Mutation()
], PersonsModule.prototype, "setTotal", null);
__decorate([
    Mutation()
], PersonsModule.prototype, "setError", null);
__decorate([
    Getter()
], PersonsModule.prototype, "list", null);
__decorate([
    Getter()
], PersonsModule.prototype, "total", null);
__decorate([
    Getter()
], PersonsModule.prototype, "errorList", null);
__decorate([
    Action()
], PersonsModule.prototype, "get", null);
