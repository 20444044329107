import { __decorate } from "tslib";
import { Component, useModule, Vue } from "@/types";
import { Button, DropDown, Icon, Tooltip } from "@/ui-kit";
import { SectionRoute } from "@/components";
import { Watch } from "vue-property-decorator";
let HeaderIndication = class HeaderIndication extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.indicateWS = {
            message: this.$l["noDate"],
            status: 2,
        };
    }
    /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types*/
    indicationUpdate(val) {
        if (val) {
            this.setStatusIndication(val.data);
            if (val.socket.closedSocket) {
                this.setStatusIndication({
                    status: 2,
                    message: this.$l["closedSocket"],
                });
            }
        }
    }
    setStatusIndication(data) {
        if (data) {
            let str = data.message;
            let array = /\[.+\].$/.exec(str);
            if (array) {
                str = str.slice(0, array.index) + "\n";
                str += array[0].split(",").join(", ");
                this.indicateWS.message = str;
            }
            else {
                this.indicateWS.message = data.message;
            }
            this.indicateWS.status = data.status;
        }
    }
    get colorIndication() {
        let color = {
            0: "green",
            1: "yellow",
            2: "red",
        };
        return color[this.indicateWS.status] ?? "red";
    }
};
__decorate([
    Watch("store.statusIndication", { immediate: true, deep: true })
], HeaderIndication.prototype, "indicationUpdate", null);
HeaderIndication = __decorate([
    Component({
        components: { SectionRoute, Button, Icon, DropDown, Tooltip },
    })
], HeaderIndication);
export default HeaderIndication;
