export class Socket {
    constructor() {
        this.socket = null;
        this.socketMessage = null;
        this.onMessageHandler = null;
        this.onOpenHandler = null;
        this.closedSocket = null;
        this.openHandler = (event) => {
            if (this.onOpenHandler) {
                this.onOpenHandler();
            }
            console.log("Websocket open!");
            return event.target;
        };
        this.messageHandler = async (event) => {
            this.socketMessage = event.data;
            if (this.onMessageHandler) {
                this.onMessageHandler();
            }
            console.log("Websocket get message!");
        };
        this.closeHandler = (event) => {
            this.closedSocket = event;
            console.log("Websocket close!");
        };
        this.destroy = () => {
            if (this.socket) {
                this.socket?.close();
                this.socket = null;
            }
        };
        this.destroy();
    }
    init(connectUrl) {
        if (this.socket)
            return;
        this.socket = new WebSocket(connectUrl);
        this.socket.addEventListener("message", this.messageHandler);
        this.socket.addEventListener("open", this.openHandler);
        this.socket.addEventListener("close", this.closeHandler);
    }
    getData() {
        return JSON.parse(this.socketMessage);
    }
}
