var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('div',{staticClass:"table__wrap"},[(_vm.loader)?_c('Loader'):_vm._e(),(_vm.headerData.length)?_c('div',{staticClass:"table__header"},_vm._l((_vm.headerData),function(head,index){return _c('div',{key:index,class:("table__header-item " + (head.class)),on:{"click":function($event){return _vm.sortClickHandler(head)}}},[_vm._t("header",function(){return [_vm._v(" "+_vm._s(head.name)+" ")]},{"headerProp":head.name}),(_vm.withSort && !head.noSort)?_c('Icon',{staticClass:"table__header-sort",class:{
            'icon--rotate': _vm.currSort.includes(((head.class) + " asc")),
          },attrs:{"name":'arrow',"color":_vm.currSort.includes(((head.class) + " asc")) ? 'main-color' : 'grey',"size":[15, 15]}}):_vm._e()],2)}),0):_vm._e(),_c('div',{ref:"content",staticClass:"table__content"},[(_vm.list.length && !_vm.loader)?_vm._l((_vm.list),function(item,index){return _c('div',{key:item.id,class:[
            'table__row',
            _vm.currentRow === item.id && _vm.activeClass,
            {
              'table__row--autoCreated': item.autoCreated && _vm.disabledRow,
            } ],on:{"click":function($event){return _vm.handleRowSelected(item, false, index)},"dblclick":function($event){return _vm.handleDoubleClick(item)}}},[_vm._t("content",null,{"contentProp":item})],2)}):_vm._e(),(!_vm.list.length && !_vm.loader)?[_c('div',{staticClass:"table__row table__row--empty"},[_vm._v(" "+_vm._s(_vm.$l["noDate"])+" ")])]:_vm._e()],2)],1),(_vm.list.length)?_c('div',{staticClass:"footer__pagination"},[_c('Pagination',{ref:"pag",attrs:{"total":_vm.total,"totalShow":_vm.totalShow,"pagination":_vm.pagination},on:{"setCurrentPage":_vm.submitPagination}})],1):_vm._e(),_c('Confirm',{on:{"confirm":_vm.confirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }