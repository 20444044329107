import { __decorate } from "tslib";
import "./table.scss";
import { Component, Prop, useModule, Watch, Emit, Ref, Vue, } from "@/types";
import { Icon, Pagination, Loader } from "@/ui-kit";
import { validationParse } from "@/helpers/validationParse";
import { Confirm } from "@/components";
import { LIST_TABLES_WITH_TOTAL } from "@/contstant";
let Table = class Table extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.pagination = {
            top: parseInt(localStorage.getItem("top")) || 10,
            skip: 0,
            orderby: this.orderBy,
            filter: this.filter,
        };
        this.currSort = "";
        this.sortAsc = !this.orderBy.includes("desc");
        this.loader = true;
        /**
         *  Для обновления стора из таблицы
         */
        this.savedIndexEdit = 0;
        this.totalShow = LIST_TABLES_WITH_TOTAL.includes(this.dataTable);
    }
    created() {
        if (this.dataTable === "users") {
            this.currSort = `createdAt asc`;
            this.pagination = { ...this.pagination, orderby: this.currSort };
        }
        this.highlightRow();
    }
    mounted() {
        window.addEventListener("load", this.setTableSize);
        window.addEventListener("resize", this.setTableSize);
    }
    /**
     * Подсветка строк
     */
    highlightRow() {
        this.updatePage().then(() => {
            if (this.list.length) {
                let isAutoCreated = this.list[0];
                if ("autoCreated" in this.list[0] && this.list[0].autoCreated) {
                    if (this.list.length === 1 && this.disabledRow) {
                        return;
                    }
                    if (this.disabledRow) {
                        this.savedIndexEdit = 1;
                        isAutoCreated = this.list[1];
                    }
                }
                this.handleRowSelected(isAutoCreated, false, this.savedIndexEdit);
            }
        });
    }
    /**
     * Инициализировать/обновить таблицу с пагиницаей
     */
    async updatePage() {
        try {
            this.loader = true;
            await this.page.get((this.pagination = {
                ...this.pagination,
                filter: this.filterConfig,
            }));
            if (this.store.dialog.curr?.name === "save" && this.savedIndexEdit) {
                const updateToStore = this.list[this.savedIndexEdit];
                this.store.tableRow.setCurrentRow(updateToStore);
            }
            else {
                this.store.tableRow.setCurrentRow(null);
            }
            this.store.dialog.removeDialog();
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.loader = false;
        }
    }
    /**
     * Удалить элемент в таблице
     */
    async confirm(canUpdateAfterRemove) {
        if (canUpdateAfterRemove) {
            try {
                this.loader = true;
                await this.updatePage();
                if (!this.list.length && this.pagination.skip !== 0) {
                    await this.page.get((this.pagination = {
                        ...this.pagination,
                        skip: this.pagination.skip -
                            (typeof this.pagination.top === "undefined"
                                ? 10
                                : Number(this.pagination.top)),
                    }));
                }
            }
            catch (e) {
                console.log(e, "Table-Remove");
            }
            finally {
                this.loader = false;
            }
        }
    }
    /**
     * Открыть диалог редактирования двойным кликом
     */
    /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
    handleDoubleClick(currentData) {
        if (!this.isHighlightsFirstRow)
            return;
        this.store.dialog.setDialog({
            data: currentData,
            route: this.$route.name?.toLowerCase(),
            type: "form",
            name: "save",
            details: this.details,
        });
        this.store.tableRow.setCurrentRow(currentData);
    }
    /**
     *
     * @param item : { class: параметр сортировки, noSort: доступна ли сортировка(по умолчанию - true)}
     */
    async sortClickHandler(item) {
        if ("noSort" in item)
            return false;
        this.currSort =
            this.currSort === `${item.class} asc`
                ? `${item.class} desc`
                : `${item.class} asc`;
        try {
            await this.page.get((this.pagination = { ...this.pagination, orderby: this.currSort }));
            return this.currSort;
        }
        catch (e) {
            console.log(e, "sortClickHandler");
            throw e;
        }
    }
    async submitPagination({ top, skip }) {
        try {
            this.loader = true;
            await this.page.get((this.pagination = { ...this.pagination, top: top, skip: skip }));
        }
        catch (e) {
            console.log(e, "submitPagination");
        }
        finally {
            this.loader = false;
        }
    }
    /**
     * Уведомления
     * @param message - TErrorsApp
     */
    notification(message) {
        this.store.notification.addNotification({
            type: "error",
            message: message,
        });
    }
    /**
     * выделить/убрать строку
     */
    handleRowSelected(val, isEdit = false, savedIndexEdit = 0) {
        if (!this.isHighlightsFirstRow)
            return;
        if (this.currentRow === val.id && !isEdit) {
            this.store.tableRow.setCurrentRow(null);
            return;
        }
        this.savedIndexEdit = savedIndexEdit;
        this.store.tableRow.setCurrentRow(val);
    }
    /**
     * Cлежение за измененеем фильтра
     * @param newVal
     */
    async filterRequest(newVal) {
        try {
            if (this.store.tableRow.current) {
                this.store.tableRow.setCurrentRow(null);
            }
            this.loader = true;
            await this.page.get((this.pagination = { ...this.pagination, skip: 0, filter: newVal }));
            if (this.list.length) {
                this.pag.goToFirstPage();
            }
        }
        catch (e) {
            console.log(e, "filter");
        }
        finally {
            this.loader = false;
        }
    }
    /**
     *
     * @param oldVal - старое значение роута
     * @param newVal - новое значение роута
     */
    onUrlChange(oldVal, newVal) {
        if (newVal !== oldVal) {
            this.$nextTick(this.setTableSize);
        }
    }
    setTableSize() {
        if (this.content) {
            const clientHeight = document.documentElement.clientHeight;
            if (clientHeight < 905) {
                this.content.style.height = clientHeight - 220 + "px";
                return;
            }
            this.content.style.height = 685 + clientHeight - 905 + "px";
        }
    }
    /**
     * Парсинг ошибок
     */
    changeError() {
        try {
            //#34904
            if (this.error) {
                const { errors, detail } = this.error.response?.data;
                if (this.error.response?.status === 500) {
                    this.store.notification.addNotification({
                        type: "error",
                        message: this.$l["serverUnavailable"],
                    });
                    return;
                }
                if (errors) {
                    /**
                     * Id не привязаны к полям ввода
                     */
                    if ("id" in errors) {
                        const join = errors["id"].join("");
                        this.notification(join);
                        return;
                    }
                    this.store.validationField.setError(validationParse(errors));
                    return;
                }
                /**
                 *  Возврат одной ошибки в поле "detail"
                 */
                if (detail) {
                    this.notification(detail);
                    return;
                }
            }
        }
        catch (e) {
            console.log(e, "changeError");
        }
    }
    /**
     * Запрос сформированной фильтрации
     */
    resetActiveRow() {
        if (this.store.tableRow.current) {
            this.store.tableRow.setCurrentRow(null);
        }
    }
    /**
     * Запросить данные для текущей страницы(в таблицу)
     */
    get page() {
        return this.store[this.dataTable];
    }
    /**
     * фильтрация по организации
     */
    get organizationFilter() {
        const parentRoute = this.$route.matched[0].name;
        return parentRoute === "FilingCabinet" || parentRoute === "TimeAttendance";
    }
    /**
     * Формирование опции фильтра
     */
    get filterConfig() {
        const supportFilter = this.store.supFilter.getSupportFilter;
        const isOrgFilter = this.organizationFilter;
        let filter = this.filter;
        if (isOrgFilter) {
            filter += `organization eq ${this.store.orgList.currentOrg?.id ?? null}`;
        }
        filter += supportFilter !== "" ? ` and ${supportFilter}` : "";
        return encodeURIComponent(filter);
    }
    /**
     * Получить ошибки из стора
     */
    get error() {
        return this.page.errorList;
    }
    /**
     * Данные api
     */
    get list() {
        return this.page.list ?? [];
    }
    /**
     * Всего записей
     */
    get total() {
        return this.page.total;
    }
    /**
     * Выделить строку
     */
    get currentRow() {
        return this.store.tableRow.current?.id;
    }
    /**
     * Задизеблить строку при инсталяции
     */
    get disabledRow() {
        //#36137
        const canEditAutoCreated = ["Zones", "Organization"];
        return !canEditAutoCreated.includes(this.$route.name);
    }
    /**
     * Активный класс для строки
     */
    get activeClass() {
        return "table__row--active";
    }
    beforeDestroy() {
        window.removeEventListener("resize", this.setTableSize);
        window.removeEventListener("load", this.setTableSize);
    }
};
__decorate([
    Ref("content")
], Table.prototype, "content", void 0);
__decorate([
    Ref("pag")
], Table.prototype, "pag", void 0);
__decorate([
    Prop({ type: String, required: true })
], Table.prototype, "dataTable", void 0);
__decorate([
    Prop({ type: Array, default: () => [], required: true })
], Table.prototype, "headerData", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], Table.prototype, "withSort", void 0);
__decorate([
    Prop({ type: String, default: "" })
], Table.prototype, "orderBy", void 0);
__decorate([
    Prop({ type: [String, Number], default: "" })
], Table.prototype, "filter", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], Table.prototype, "isHighlightsFirstRow", void 0);
__decorate([
    Prop({ default: false })
], Table.prototype, "details", void 0);
__decorate([
    Prop({ default: false, type: [Number, Boolean] })
], Table.prototype, "staticHeight", void 0);
__decorate([
    Prop({ default: 500, type: Number })
], Table.prototype, "debounce", void 0);
__decorate([
    Emit("confirm")
], Table.prototype, "confirm", null);
__decorate([
    Emit("sortClickHandler")
], Table.prototype, "sortClickHandler", null);
__decorate([
    Watch("filterConfig")
], Table.prototype, "filterRequest", null);
__decorate([
    Watch("$route", { immediate: true })
], Table.prototype, "onUrlChange", null);
__decorate([
    Watch("error")
], Table.prototype, "changeError", null);
__decorate([
    Watch("pagination", { deep: true })
], Table.prototype, "resetActiveRow", null);
Table = __decorate([
    Component({
        components: { Loader, Confirm, Pagination, Icon },
    })
], Table);
export default Table;
